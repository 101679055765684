<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-alert
      type="info"
      color="lime darken-2"
      :value="true"
      class="mb-4"
      outline
    >
      Kindly read and understand the
      <strong>
        <a
          href="https://onesys.onexox.my/form/[T&C]Kempen_Semua_Menang_Q2.pdf"
          target="_blank"
        >
          Terms &amp; Conditions
        </a>
      </strong>
      and
      <strong>
        <a
          href="https://onesys.onexox.my/form/[FAQ]Kempen_Semua_Menang_Q2.pdf"
          target="_blank"
        >
          Frequently Ask Questions (FAQ)
        </a>
      </strong>
      of the Semua Menang Campaign before participating.
    </v-alert>
    <v-alert
      type="info"
      color="green darken-2"
      :value="true"
      class="mb-4"
      outline
    >
      <strong>
        Do take note :
      </strong>
      The tokens here consists of the mobile plan activations and dealer's recruitment.
      The
      <strong>
        PK15 registrations
      </strong>
      and
      <strong>
        EPP sales
      </strong>
      will be added in the following month.
    </v-alert>
    <v-toolbar
      dense
      card
    >
      <v-menu
        v-model="menu"
        lazy
        close-on-content-click
        transition="scale-transition"
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="monthOf"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          v-model="monthOf"
          :max="maxDate"
          :allowed-dates="allowedMonths"
          min="2021-04"
          type="month"
          year-icon="date_range"
        />
      </v-menu>
      <v-spacer />
    </v-toolbar>
    <v-card-title class="title">
      Activation Tokens
    </v-card-title>
    <v-alert
      :value="alert"
      type="error"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      :headers="activationHeaders"
      :items="activations"
      :loading="loading"
      item-key="activation"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-left">
            {{ props.item.plan }}
          </td>
          <td class="text-xs-center">
            <v-icon
              v-if="props.item.affiliate"
              color="success"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-else
              color="error"
            >
              mdi-close
            </v-icon>
          </td>
          <td class="text-xs-center primary--text">
            {{ props.item.token }}
          </td>
        </tr>
      </template>
      <template
        v-if="totalActivationTokens"
        #footer
      >
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td class="text-xs-center primary white--text">
            {{ totalActivationTokens }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <p />
    <v-card-title class="title">
      Recruitment Tokens
    </v-card-title>
    <v-data-table
      :headers="recruitmentHeaders"
      :items="recruitments"
      :loading="loading"
      item-key="recruitment"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td
            v-if="props.item.type === 'Standard'"
            class="text-xs-left green--text"
          >
            {{ props.item.type }}
          </td>
          <td
            v-else
            class="text-xs-left teal--text"
          >
            {{ props.item.type }}
          </td>
          <td class="text-xs-center primary--text">
            {{ props.item.token }}
          </td>
        </tr>
      </template>
      <template
        v-if="totalRecruitmentTokens"
        #footer
      >
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td class="text-xs-center primary white--text">
            {{ totalRecruitmentTokens }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <p />
    <v-card-title class="title">
      Epp Tokens
    </v-card-title>
    <v-data-table
      :headers="eppHeaders"
      :items="epps"
      :loading="loading"
      item-key="epp"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-left">
            {{ props.item.product }}
          </td>
          <td class="text-xs-center">
            {{ props.item.quantity }}
          </td>
          <td class="text-xs-center">
            {{ props.item.sales }}
          </td>
        </tr>
      </template>
      <template
        v-if="eppToken"
        #footer
      >
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td class="text-xs-center">
            Total:
          </td>
          <td class="text-xs-center primary--text">
            RM {{ eppTotal }}
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td class="text-xs-center">
            Token:
          </td>
          <td class="text-xs-center primary white--text">
            {{ eppToken }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const title = 'Semua Menang Token'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Campaigns', disabled: false, to: '/campaigns',
        },
        {
          text: title, disabled: true,
        },
      ],
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2020, 1, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      activations: [],
      activationHeaders: [
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Plan',
          align: 'left',
          sortable: true,
          value: 'plan',
        },
        {
          text: 'Affiliate',
          align: 'center',
          sortable: true,
          value: 'affiliate',
        },
        {
          text: 'Token',
          align: 'center',
          sortable: true,
          value: 'token',
        },
      ],
      recruitments: [],
      recruitmentHeaders: [
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Dealer Type',
          align: 'left',
          sortable: true,
          value: 'type',
        },
        {
          text: 'Token',
          align: 'center',
          sortable: true,
          value: 'token',
        },
      ],
      epps: [],
      eppHeaders: [
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Product',
          align: 'left',
          sortable: true,
          value: 'product',
        },
        {
          text: 'Quantity',
          align: 'center',
          sortable: true,
          value: 'product',
        },
        {
          text: 'Sales (RM)',
          align: 'center',
          sortable: true,
          value: 'sales',
        },
      ],
      loading: false,
      alert: false,
      alertMessage: null,
      maxDate: '',
      menu: false,
      monthOf: null,
      title,
      totalRecruitmentTokens: 0,
      totalActivationTokens: 0,
      eppTotal: 0.00,
      eppToken: 0,
    }
  },
  watch: {
    monthOf: function (val) {
      this.getSemuaMenangToken(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    getSemuaMenangToken: function (month) {
      const param = month ? { month } : {}
      this.loading = true
      this.totalRecruitmentTokens = 0
      this.totalActivationTokens = 0
      this.eppTotal = '0.00'
      this.eppToken = 0
      this.$rest.get('getSemuaMenangToken.php', createGetParams(param))
        .then(function (response) {
          this.recruitments = response.data.recruitment
          this.recruitments.forEach(item => {
            this.totalRecruitmentTokens += item.token * 1
          })
          this.activations = response.data.activation
          this.activations.forEach(item => {
            this.totalActivationTokens += item.token * 1
          })
          this.epps = response.data.epp
          this.eppTotal = response.data.eppTotal
          this.eppToken = response.data.eppToken
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alert = true
        })

      this.loading = false
    },
  },
}
</script>
